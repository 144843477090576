import React, { FormEvent } from 'react'
import {
  Set_AsideComponents_Newsletter,
  Set_InsertComponents_Newsletter,
  useSubscribeToNewsletterMutation,
} from '@graphql/generated'
import Bard from '@components/common/Bard/Bard'
import Button from '@components/common/Button'
import cx from 'classnames'
import FlatExplosionIllustration from '@components/illustrations/FlatExplosionIllustration'
import useInput from '@hooks/useInput'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import FormField from '@components/forms/fields/FormField'
import Input from '@components/forms/fields/Input'
import { UnprocessableEntity } from '@lib/fetcher'
import * as Fathom from 'fathom-client'

type Props = (Set_InsertComponents_Newsletter | Set_AsideComponents_Newsletter) & {
  aside?: boolean
}

type FormValues = {
  name: string
  email: string
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('Name'),
  email: Yup.string().required().email().label('E-Mail'),
})

const Newsletter: React.FC<Props> = (props) => {
  const subscribeMutation = useSubscribeToNewsletterMutation()

  const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    subscribeMutation.mutate(values, {
      onError: (error) => {
        if (error instanceof UnprocessableEntity) {
          actions.setErrors(error.getSingleValidationErrors())
        }
      },
      onSuccess: () => {
        Fathom.trackGoal('XPBQYFTB', 0)
      },
    })
  }

  return (
    <section
      className={cx('relative isolate overflow-hidden bg-white transition-all', {
        'md:shadow-md hover:md:shadow-lg': !props.aside,
      })}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{ name: '', email: '' }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isValid }) => (
          <form
            onSubmit={handleSubmit}
            className={cx(
              'flex flex-col items-start space-y-5 pl-7 pr-7 pb-8 pt-7 md:pr-12 md:pt-7 md:pb-8 md:pl-8',
              {
                'lg:pl-7 lg:pr-7 lg:pb-8 lg:pt-7': props.aside,
              }
            )}
          >
            <div className="w-full flex-1 space-y-2 md:space-y-3">
              <h2 className={cx(props.aside ? 'typo-600' : 'typo-700')}>{props.title}</h2>

              <Bard
                html={
                  subscribeMutation.data?.result?.success === true
                    ? props.thank_you_text
                    : props.text
                }
                className="typo-200-regular xs:max-w-[80%] sm:max-w-[60%] md:max-w-full"
              />
            </div>

            {subscribeMutation.data?.result?.success !== true && (
              <div
                className={cx(
                  'flex w-full shrink-0 flex-col items-start space-y-5 md:flex-row md:space-y-0 md:space-x-4 lg:flex-col lg:space-x-0 lg:space-y-5',
                  {
                    'xl:flex-row xl:space-y-0 xl:space-x-4': !props.aside,
                  }
                )}
              >
                <div
                  className={cx(
                    'grid w-full grow grid-cols-1 gap-y-2 gap-x-3 md:grid-cols-2 lg:grid-cols-1',
                    {
                      'xl:grid-cols-2': !props.aside,
                    }
                  )}
                >
                  <FormField name="name">
                    <Input placeholder="Name" autoComplete="name" />
                  </FormField>
                  <FormField name="email">
                    <Input type="email" placeholder="E-Mail" />
                  </FormField>
                </div>

                <div className="relative shrink-0">
                  <Button
                    type="submit"
                    disabled={!isValid || subscribeMutation.isLoading}
                    className={cx('w-full md:h-[3.375rem] lg:h-auto', {
                      'xl:h-[3.375rem]': !props.aside,
                    })}
                  >
                    {props.button_text}
                  </Button>
                  <FlatExplosionIllustration
                    className={cx(
                      'absolute -bottom-10 left-2/3 -z-1 hidden w-60 -translate-x-1/2 text-gray-50 md:block lg:hidden',
                      {
                        'xl:block': !props.aside,
                      }
                    )}
                  />
                </div>
              </div>
            )}

            {subscribeMutation.data?.result?.message && (
              <Bard
                html={subscribeMutation.data?.result?.message}
                className="typo-200-regular xs:max-w-[80%] sm:max-w-[60%] md:max-w-full"
              />
            )}
          </form>
        )}
      </Formik>
    </section>
  )
}

Newsletter.defaultProps = {
  aside: false,
}

export default Newsletter
